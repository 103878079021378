var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"员工姓名","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name',
        {
          rules: [{ required: true, message: '请输入' }],
          initialValue: this.user.name,
        },
      ]),expression:"[\n        'name',\n        {\n          rules: [{ required: true, message: '请输入' }],\n          initialValue: this.user.name,\n        },\n      ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"手机号","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'phone',
        {
          rules: [
            {
              required: true,
              pattern: /^1\d{10}$/,
              message: '用户手机号格式错误！',
            },
          ],
          initialValue: this.user.phone,
        },
      ]),expression:"[\n        'phone',\n        {\n          rules: [\n            {\n              required: true,\n              pattern: /^1\\d{10}$/,\n              message: '用户手机号格式错误！',\n            },\n          ],\n          initialValue: this.user.phone,\n        },\n      ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"钉钉部门ID","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'dingtalkDeptId',
        {
          rules: [
            {
              required: false,
              pattern: /^[0-9]*$/,
              message: '钉钉部门ID有误差！',
            },
          ],
          initialValue: this.user.dingtalkDeptId,
        },
      ]),expression:"[\n        'dingtalkDeptId',\n        {\n          rules: [\n            {\n              required: false,\n              pattern: /^[0-9]*$/,\n              message: '钉钉部门ID有误差！',\n            },\n          ],\n          initialValue: this.user.dingtalkDeptId,\n        },\n      ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"钉钉用户ID","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'dingtalkUserId',
        {
          rules: [{ required: false, message: '请输入钉钉用户ID！' }],
          initialValue: this.user.dingtalkUserId,
        },
      ]),expression:"[\n        'dingtalkUserId',\n        {\n          rules: [{ required: false, message: '请输入钉钉用户ID！' }],\n          initialValue: this.user.dingtalkUserId,\n        },\n      ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"邮箱","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'email',
        {
          rules: [{ required: true, message: '邮箱格式错误！' }],
          initialValue: this.user.email,
        },
      ]),expression:"[\n        'email',\n        {\n          rules: [{ required: true, message: '邮箱格式错误！' }],\n          initialValue: this.user.email,\n        },\n      ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"所属门店","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'facilityIds',
        {
          rules: [{ required: true, message: '请选择所属门店' }],
          initialValue: this.user.facilityIds,
        },
      ]),expression:"[\n        'facilityIds',\n        {\n          rules: [{ required: true, message: '请选择所属门店' }],\n          initialValue: this.user.facilityIds,\n        },\n      ]"}],attrs:{"tree-data":_vm.facilityTree,"tree-checkable":"","placeholder":"请选择","dropdownClassName":"facility-dropdown","maxTagCount":10}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }