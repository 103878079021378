<template>
  <a-modal
    :title="title"
    :width="640"
    :visible="visible"
    destroyOnClose
    okText="选择"
    :confirm-loading="loading"
    @ok="onSubmit"
    @cancel="close"
  >
    <a-select
      size="default"
      v-model="selectedRole"
      style="width: 100%"
      placeholder="选择一个选项"
      @change="handleSelectChange"
    >
      <a-select-option v-for="role in this.unselectedRoles()" :key="role.uuid">
        {{ role.name }}
      </a-select-option>
    </a-select>
  </a-modal>
</template>

<script>
import { mapActions } from "vuex";
import { ADD_ROLES_TO_USER } from "../../../store/modules/actions.type";

export default {
  name: "addUserRoleModal",
  props: ["roles", "type", "userDetails", "visible", "title"],

  data() {
    return {
      selectedRole: undefined,
      loading: false
    };
  },

  methods: {
    ...mapActions("users", [ADD_ROLES_TO_USER]),

    onSubmit: async function() {
      this.loading = true;
      await this.addRolesToUser({
        roleIds: [this.selectedRole],
        roles: this.roles,
        userId: this.userDetails.user.id,
        type: this.type
      });
      this.close();
    },
    close() {
      this.loading = false;
      this.selectedRole = undefined;
      this.$emit("close");
    },
    handleSelectChange(role) {
      this.selectedRole = role;
    },
    unselectedRoles() {
      return (this.roles.get(this.type) || []).filter(
        role =>
          !this.userDetails.roles[this.type].some(
            userRole => userRole.uuid === role.uuid
          )
      );
    }
  }
};
</script>

<style scoped></style>
