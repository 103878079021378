<template>
  <a-spin :spinning="loading">
    <div>
      <div style="min-width: 1240px">
        <a-card
          :bordered="false"
          :tabList="tabs"
          :activeTabKey="currentTab"
          @tabChange="tab => (this.currentTab = tab)"
        >
          <a-card :bordered="false" v-if="!loading">
            <edit-user-details
              @onDetailsForm="form => (this.detailsForm = form)"
              v-bind:user="userDetails.user"
              v-bind:facilities="facilities"
              v-bind:cities="userCities"
              v-bind:visible="currentTab === 'baseDetail'"
            />
            <edit-user-roles
              :userDetails="userDetails"
              v-bind:roles="roles"
              v-bind:visible="currentTab === 'roles'"
              v-bind:cities="userCities"
            />
          </a-card>
        </a-card>
        <div style="margin-top: 8px">
          <a-card :bordered="false">
            <div class="steps-action">
              <a-button @click="goBack">取消</a-button>
              <a-button type="primary" style="margin-left: 8px" @click="save">
                <a-spin :spinning="saving">保存</a-spin>
              </a-button>
            </div>
          </a-card>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_FACILITIES,
  GET_ROLES,
  GET_USER_DETAILS,
  UPDATE_USER
} from "../../store/modules/actions.type";
import Bus from "../../bus";
import EditUserDetails from "./detail/editUserDetails";
import EditUserRoles from "./detail/editUserRoles";
import { RoleTypes } from "../../store/modules/roles";

export default {
  name: "editUser",

  components: {
    EditUserRoles,
    EditUserDetails
  },

  created: async function() {
    this.loading = true;
    const promises = [];

    promises.push(this.getUserDetails(this.userId));

    promises.push(this.getRoles(RoleTypes.USER_ROLE));
    promises.push(this.getRoles(RoleTypes.CITY));
    promises.push(this.getRoles(RoleTypes.FACILITY));

    if (this.facilities.length === 0) {
      promises.push(this.getFacilities());
    }

    await Promise.all(promises);
    this.loading = false;
    Bus.$on("getTarget", () => {
      this.$router.push({
        path: "/authorization/users/" + this.userId
      });
    });
  },

  data() {
    return {
      userId: this.$route.params.userId,
      loading: false,
      saving: false,

      detailsForm: undefined,
      updatedUserRoles: undefined,

      tabs: [
        {
          key: "baseDetail",
          tab: "基本信息"
        },
        {
          key: "roles",
          tab: "角色和权限"
        }
      ],
      currentTab: "baseDetail"
    };
  },

  computed: {
    ...mapGetters("roles", ["roles"]),
    ...mapGetters("cities", ["userCities"]),
    ...mapGetters("facilities", ["facilities"]),

    userDetails: function() {
      return this.$store.getters["users/user"](this.userId) || {};
    }
  },

  methods: {
    ...mapActions("users", [UPDATE_USER, GET_USER_DETAILS]),
    ...mapActions("roles", [GET_ROLES]),
    ...mapActions("facilities", [GET_FACILITIES]),

    save() {
      let that = this;
      const {
        detailsForm: { validateFields }
      } = this;
      validateFields(async (errors, values) => {
        if (!errors) {
          that.saving = true;
          await that.updateUser({
            ...that.userDetails.user,
            ...values
          });
          that.saving = false;
          this.goBack();
        }
      });
    },

    goBack() {
      this.$router.push({ path: "/authorization/users" });
    }
  }
};
</script>

<style scoped></style>
