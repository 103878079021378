const ROLE_NAME_REGEX = /\(([^)]+)\)/;

export function getAddedRoles(oldRoles, newRoles) {
  const oldRolesSet = new Set(oldRoles);

  const result = [];

  newRoles.forEach(role => {
    if (!oldRolesSet.has(role)) {
      result.push(role);
    }
  });

  return result;
}

export function getRemovedRoles(oldRoles, newRoles) {
  const newRolesSet = new Set(newRoles);

  const result = [];

  oldRoles.forEach(role => {
    if (!newRolesSet.has(role)) {
      result.push(role);
    }
  });

  return result;
}

// ex: 用户角色(全国) => 全国
export function getSimplifiedResourceRoleName(roleName) {
  const parts = ROLE_NAME_REGEX.exec(roleName);
  if (parts && parts.length > 1) {
    return parts[1];
  }
  return roleName;
}
