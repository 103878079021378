<template>
  <div v-if="visible" style="display:flex; justify-content:space-between">
    <a-card class="roleCard">
      <h1>用户角色</h1>
      <div v-if="userRoles.length === 0">
        <a-card style="margin: 12px">
          <p style="margin: 0">没有用户角色</p>
        </a-card>
      </div>
      <div v-for="role in userRoles" :key="role.uuid">
        <role-card
          v-bind:role="role"
          :type="`USER_ROLE`"
          @onDelete="roleId => removeRole(roleId, `USER_ROLE`)"
        />
      </div>
      <a-button
        type="primary"
        style="margin: 24px"
        @click="() => (this.addRoleVisible = true)"
        >添加角色</a-button
      >
    </a-card>
    <a-card class="roleCard">
      <h1>用户城市</h1>
      <div v-if="userCityRoles.length === 0">
        <a-card style="margin: 12px">
          <p style="margin: 0">没有用户城市</p>
        </a-card>
      </div>
      <div v-for="role in userCityRoles" :key="role.uuid">
        <role-card
          v-bind:role="role"
          :type="`CITY`"
          @onDelete="roleId => removeRole(roleId, `CITY`)"
        />
      </div>
      <a-button
        type="primary"
        style="margin: 24px"
        @click="() => (this.addCityRoleVisible = true)"
      >
        添加用户城市
      </a-button>
    </a-card>
    <a-card class="roleCard">
      <h1>用户门店</h1>
      <div v-if="userFacilityRoles.length === 0">
        <a-card style="margin: 12px">
          <p style="margin: 0">没有用户门店</p>
        </a-card>
      </div>
      <div v-for="role in userFacilityRoles" :key="role.uuid">
        <role-card
          v-bind:role="role"
          :type="`FACILITY`"
          @onDelete="roleId => removeRole(roleId, `FACILITY`)"
        />
      </div>
      <a-button
        type="primary"
        style="margin: 24px"
        @click="() => (this.addFacilityRoleVisible = true)"
        >添加用户门店</a-button
      >
    </a-card>
    <add-user-role-modal
      @close="() => (this.addRoleVisible = false)"
      v-bind:visible="addRoleVisible"
      v-bind:roles="roles"
      v-bind:type="`USER_ROLE`"
      v-bind:title="`选择角色`"
      v-bind:userDetails="userDetails"
    />
    <add-user-role-modal
      @close="() => (this.addCityRoleVisible = false)"
      v-bind:visible="addCityRoleVisible"
      v-bind:roles="roles"
      v-bind:type="`CITY`"
      v-bind:title="`选择城市`"
      v-bind:userDetails="userDetails"
    />
    <add-user-role-modal
      @close="() => (this.addFacilityRoleVisible = false)"
      v-bind:visible="addFacilityRoleVisible"
      v-bind:roles="roles"
      v-bind:type="`FACILITY`"
      v-bind:title="`选择门店`"
      v-bind:userDetails="userDetails"
    />
  </div>
</template>

<script>
import RoleCard from "./roleCard";
import { mapActions } from "vuex";
import { REMOVE_ROLES_FROM_USER } from "../../../store/modules/actions.type";
import { modal } from "ant-design-vue";
import AddUserRoleModal from "./addUserRoleModal";
import { RoleTypes } from "../../../store/modules/roles";
export default {
  name: "editUserRoles",
  components: { AddUserRoleModal, RoleCard },
  props: ["userDetails", "roles", "visible", "cities"],

  data() {
    return {
      addRoleVisible: false,
      addCityRoleVisible: false,
      addFacilityRoleVisible: false
    };
  },

  computed: {
    userRoles() {
      return this.userDetails.roles[RoleTypes.USER_ROLE];
    },
    userCityRoles() {
      return this.userDetails.roles[RoleTypes.CITY];
    },
    userFacilityRoles() {
      return this.userDetails.roles[RoleTypes.FACILITY];
    }
  },

  methods: {
    ...mapActions("users", [REMOVE_ROLES_FROM_USER]),
    removeRole(roleId, type) {
      const that = this;
      modal.confirm({
        title: "确定要删除该角色吗?",
        content: "该操作无法撤回，你还要继续吗？",
        onOk() {
          return that.removeRolesFromUser({
            roleIds: [roleId],
            userId: that.userDetails.user.id,
            type: type
          });
        },
        onCancel() {}
      });
    }
  }
};
</script>

<style scoped>
.roleCard {
  width: 100%;
  margin: 0 10px;
}
</style>
