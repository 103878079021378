<template>
  <a-form :form="form" v-if="visible">
    <a-form-item label="员工姓名" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="[
          'name',
          {
            rules: [{ required: true, message: '请输入' }],
            initialValue: this.user.name,
          },
        ]"
        placeholder="请输入"
      />
    </a-form-item>
    <a-form-item label="手机号" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="[
          'phone',
          {
            rules: [
              {
                required: true,
                pattern: /^1\d{10}$/,
                message: '用户手机号格式错误！',
              },
            ],
            initialValue: this.user.phone,
          },
        ]"
        placeholder="请输入"
      />
    </a-form-item>
    <a-form-item
      label="钉钉部门ID"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
    >
      <a-input
        v-decorator="[
          'dingtalkDeptId',
          {
            rules: [
              {
                required: false,
                pattern: /^[0-9]*$/,
                message: '钉钉部门ID有误差！',
              },
            ],
            initialValue: this.user.dingtalkDeptId,
          },
        ]"
        placeholder="请输入"
      />
    </a-form-item>
    <a-form-item
      label="钉钉用户ID"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
    >
      <a-input
        v-decorator="[
          'dingtalkUserId',
          {
            rules: [{ required: false, message: '请输入钉钉用户ID！' }],
            initialValue: this.user.dingtalkUserId,
          },
        ]"
        placeholder="请输入"
      />
    </a-form-item>
    <a-form-item label="邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="[
          'email',
          {
            rules: [{ required: true, message: '邮箱格式错误！' }],
            initialValue: this.user.email,
          },
        ]"
        placeholder="请输入"
      />
    </a-form-item>
    <a-form-item label="所属门店" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-tree-select
        :tree-data="facilityTree"
        tree-checkable
        placeholder="请选择"
        dropdownClassName="facility-dropdown"
        :maxTagCount=10
        v-decorator="[
          'facilityIds',
          {
            rules: [{ required: true, message: '请选择所属门店' }],
            initialValue: this.user.facilityIds,
          },
        ]"
      />
    </a-form-item>
  </a-form>
</template>

<script>
export default {
  name: "editUserDetails",
  props: ["user", "visible", "facilities", "cities"],

  created() {
    this.$emit("onDetailsForm", this.form);
  },

  data() {
    return {
      form: this.$form.createForm(this, {
        onValuesChange: () => this.$emit("onDetailsForm", this.form),
        onFieldsChange: () => this.$emit("onDetailsForm", this.form),
      }),
      labelCol: {
        md: { span: 2 },
        sm: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
      },
    };
  },
  computed: {
    // 把门店信息转换成TreeSelect组件能读懂的对象结构
    facilityTree() {
      let tree = this.cities.map((c) => {
        return {
          title: c.name,
          value: c.id,
          key: c.id,
          children: [],
        };
      });

      for (const facility of this.facilities) {
        let city = tree.find((c) => c.value === facility.cityId);
        if (city) {
          city.children.push({
            title: facility.optionText,
            value: facility.id,
            key: `f${facility.id}`,
          });
        }
      }
      return tree;
    },
  },
};
</script>

<style >
.facility-dropdown {
  height: 400px;
}
</style>
