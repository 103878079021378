<template>
  <div style="display: flex; align-items: center">
    <a-card style="margin: 12px; width: 100%">
      <div style="display: flex" @click="this.onClick">
        <div>{{ title() }}</div>
        <a-icon v-if="editable" type="right" style="margin-left:auto" />
      </div>
    </a-card>
    <a-icon type="close" @click="this.onDelete" />
  </div>
</template>

<script>
import { getSimplifiedResourceRoleName } from "../../../utils/roles";
import { RoleTypes } from "../../../store/modules/roles";

export default {
  name: "roleCard",

  props: ["role", "type"],

  computed: {
    editable() {
      return this.role.type === RoleTypes.USER_ROLE;
    }
  },

  methods: {
    onClick() {
      if (!this.editable) {
        return;
      }
      this.$router.push({
        path: `/authorization/roles/${this.role.uuid}`
      });
    },
    onDelete() {
      this.$emit("onDelete", this.role.uuid);
    },
    title() {
      return this.type === `USER_ROLE`
        ? `${this.role.name} (${this.role.description})`
        : getSimplifiedResourceRoleName(this.role.name);
    }
  }
};
</script>

<style scoped></style>
